<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ !!qrId ? 'QRコード編集' : 'QRコード登録' }}</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="!$permission.isStore()">
                      <template v-slot:label>{{ !$permission.isGroup() ? '店舗名' : '店舗選択' }}</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div v-if="!$permission.isGroup()">{{ shopName }}</div>
                          <div class="form-select" v-else>
                            <select
                              :class="{ 'form-control form-select-input': true, 'is-error': errors.shopId }"
                              name="shopId"
                              v-model="form.shopId"
                            >
                              <option value="" selected>選択してください</option>
                              <option
                                v-for="item in qrShops"
                                :key="item.id"
                                :value="item.id"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>商品名・サービス名</template>
                      <template v-slot:labelNote>（50文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-maxlength
                            maxlength="50"
                            class="form-control"
                            :class="{ 'is-error': errors.name }"
                            type="text"
                            name="name"
                            v-model="form.name"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>金額</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                v-maxlength
                                maxlength="6"
                                class="form-control"
                                :class="{ 'w-100': true, 'is-error': errors.amount }"
                                type="tel"
                                v-number
                                name="amount"
                                v-model="form.amount"
                              />
                            </div>
                            <div class="form-group-item">
                              円（税込）
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'ShopQr' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="handleSubmit" :button-text="buttonText" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import nav from '@/mixins/nav/shopQr';
import error from '@/mixins/plugin/error';
import FormRow from '@/components/FormRow.vue';

export default {
  data: function() {
    return {
      pageName: '通貨管理',
      form: {
        shopId: '',
        name: null,
        amount: null,
      },
      storeModule: 'qrCode',
    }
  },
  mixins: [nav, error],
  components: {
    FormRow,
  },
  validations() {
    let form = {
      shopId: {},
      name: { required, maxLength: maxLength(50) },
      amount: { required, maxLength: maxLength(6) },
    }
    if (this.$permission.isGroup()) {
      form.shopId = { selectRequired: required };
    }
    if (!!this.form.amount && this.form.amount <= 0) {
      form.amount = { isAmountNotEmpty: () => false };
    }
    return { form };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      qrDetail: 'qrCode/qrDetail',
      qrShops: 'qrCode/qrShops',
      shopDetail: 'shop/shopDetail',
    }),
    directory() {
      return this.$route.params?.directory;
    },
    qrId() {
      return this.$route.params.id;
    },
    buttonText() {
      return this.qrId ? '編集する' : '登録する';
    },
    shopName() {
      return this.$permission.isStore() ? this.infor?.shop?.name : this.shopDetail?.name;
    }
  },
  watch: {
    $route: function(to, from) {
      if (to.name === 'ShopQrRegister' && from.name === 'ShopQrEdit') {
        window.location.reload();
      }
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getQrDetail(),
      this.getQrShops(),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
  methods: {
    async getQrShops() {
      await this.$store.dispatch(`${this.storeModule}/getQrShopsList`, { subdomain: this.subdomain });
    },
    async getQrDetail() {
      if (this.qrId) {
        const qrDetail = await this.$store.dispatch(`${this.storeModule}/getQRDetail`, {
          id: this.qrId,
          subdomain: this.subdomain
        });
        const { shop, ...form} = qrDetail;
        this.form = {
          ...form,
          shopId: shop?.id,
        }
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'shopQr');
        return false;
      } else {
        const action = this.qrId ? 'updateQr' : 'createQr';
        const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
          subdomain: this.subdomain,
          ...this.form,
          shopId: this.$permission.isGroup() ? this.form.shopId : this.$permission.isStore() ? this.infor?.shop?.id : this.shopDetail?.id,
        });
        if (result) {
          const actionName = this.qrId ? 'updated' : 'created';
          await this.$router.push({ name: 'ShopQr' });
          this.$message[actionName]('pointQR');
        }
      }
    },
    handleSelectedIssuer(item) {
      this.form.issuer = item?.value;
    },
  },
}
</script>
<style lang="scss" scoped>
.listGroup {
  padding-left: 30px;
  &-item-child {
    margin-left: 15px;
  }
}

.listGroup-qr {
  padding: 16px 0;
  min-width: 500px;
  margin-left: 20px;
  &:not(:last-child) {
    border: 0 !important;
  }
}

.listGroup-item {
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.listGroup-item .form-radio,
.listGroup-item .form-check {
  display: inline-flex;
  margin-bottom: 15px;
}

.form-group-item input {
  width: 80px;
}

.form-check-card {
  gap: 15px;
  font-weight: bold;
}

.form-group-checked {
  flex: 0 0 auto;
  width: 60%;
}

.form-group-selected {
  flex: 0 0 auto;
  width: 27%;
}
</style>
